import "./app.css";

import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router } from "react-router";

import { Landing, Shira, Yael } from "./pages";

const history = createBrowserHistory();

export const App: React.SFC = () => {
  return (
      <div className="app">
        <Router history={history}>
            <Route path="/" exact component={Landing} />
            <Route path="/shira/" component={Shira} />
            <Route path="/yael/" component={Yael} />
        </Router>
      </div>
  );
}
