
import React from 'react';

import { DateTime } from "luxon";
import { DateCounter } from './dateCounter';

const TIMESTAMP_YAEL = DateTime.fromISO('2019-06-11T20:00:00.000', { zone: 'America/Los_Angeles' });

export class Yael extends React.PureComponent {
    public render() {
        return <DateCounter startDate={TIMESTAMP_YAEL} startDateName={'Hulking Out'} />
    }
}