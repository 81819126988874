
import React from 'react';

import { DateTime } from "luxon";
import { DateCounter } from './dateCounter';

const TIMESTAMP_NO_PRESSURE = DateTime.fromISO('2019-04-27T18:00:00.000', { zone: 'America/Los_Angeles' });

export class Shira extends React.PureComponent {
    public render() {
        return <DateCounter startDate={TIMESTAMP_NO_PRESSURE} startDateName={'"No Pressure"'} />
    }
}