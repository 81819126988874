
import React from 'react';
import { DateTime } from "luxon";

export interface IDateCounterProps {
    startDate: DateTime;
    startDateName: string;
}

export class DateCounter extends React.PureComponent<IDateCounterProps> {
    private secondsRef: React.RefObject<HTMLDivElement>;
    private loopToken: any;

    constructor(props: IDateCounterProps) {
      super(props);
      this.secondsRef = React.createRef();
    }

    public render() {
        const { startDate, startDateName } = this.props;
        const diff = startDate.diffNow().negate();
        const nextMonthiversary = startDate.plus({ months: Math.ceil(diff.as("months")) });
        const diffNextMonthiversary = nextMonthiversary.diffNow();

        return (
            <section>
                <h4>Time since {startDateName}</h4>
                <div className="time">
                    {diff.as("months").toPrecision(2)} months
                </div>
                <div className="time time-small" ref={this.secondsRef} />

                <h4>Time until next monthiversary ({nextMonthiversary.toLocaleString()})</h4>
                <div className="time">
                    {diffNextMonthiversary.as("days").toPrecision(2)} days
                </div>
            </section>
        );
    }

    public componentDidMount() {
        this.loopToken = setTimeout(this.renderLoop, 200);
    }

    public componentWillUnmount() {
        clearTimeout(this.loopToken);
    }

    private renderLoop = () => {
        const diff = this.props.startDate.diffNow().negate();
        this.secondsRef.current.textContent = `${Math.round(diff.as("seconds")).toLocaleString()} secs`
        this.loopToken = setTimeout(this.renderLoop, 200);
    }
}